<template>
    <Dialog v-model:visible="showModal"
        :modal="true" :closable="false" :style="{width: '50vw'}">
    <template #header>
        <h3>Confirmation</h3>
    </template>
        <div class="container-fluid mb-2">
            <span v-html="messages.visitValidation.visitFinalizeConfirmation"></span>
        </div>
    <template #footer>
        <Button class="me-1" label="OK" @click="finalizeVisit" :disabled="displayLoader"/>
        <Button label="CANCEL" @click="$emit('toggleFinalizeModal', false)" class="me-3 p-button-outlined" :disabled="displayLoader"/>
    </template>
    <ProgressSpinner
            class="p-component-overlay position-fixed w-100 h-100"
            strokeWidth="4"
            v-if="displayLoader"
            />
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { VisitService } from '../../../../apis';
import { UserActivity } from '../../../../shared/utils';
import { VisitStatus } from '../../../../shared/enums/visit-status';
import { toasterTime, messages, userActivities, toasterType } from '../../../../shared/constants/constants';
import ProgressSpinner from 'primevue/progressspinner';


export default {
    name: 'CertifyVisit',
    components: {
        Dialog,
        Button,
        ProgressSpinner
    },
    props: {
        visit: Object
    },
    data() {
        return {
            showModal: true,
            data: [],
            messages,
            userActivities,
            displayLoader: false
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async finalizeVisit() {
            try {
                this.displayLoader = true;
                const visitData = this.visit;
                visitData.state = VisitStatus.SentToServiceNow;
                visitData.info.updated = (UserActivity.getActivity(userActivities.updated)).updated,
                await VisitService.update(this.visit.id, visitData);
                this.showToaster(messages.visitValidation.visitFinalizeSuccess, toasterType.success);
                this.$emit('toggleFinalizeModal', true);
            } catch (err) {
                this.showToaster(messages.visitValidation.visitFinalizeFailed, toasterType.error);
            } finally {
                this.displayLoader = false;
            }
        }
    }
};
</script>
<style>
.p-progress-spinner-svg {
    width: 6.25rem !important;
}
</style>

