<template>
    <Dialog :closable="false" v-model:visible="showDialog" :style="{ width: '80vw' }" :modal="true">
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div v-if="assetData.original">
                        <div>
                            {{ assetData.original.assetTag?.value || assetData.original.assetTag }} ({{
                                assetData.original.serialNumber?.value || assetData.original.serialNumber
                            }})
                        </div>
                        <div>
                            {{ assetData.assetType }}
                        </div>
                    </div>
                    <div v-else>{{ NA }}</div>
                </div>
                <div class="col-md-6 text-end">
                    <div class="fw-bold">Status</div>
                    <div>
                        {{ getStatus(assetData.state) }}
                        <template v-if="assetData.state === AssetState.ManualVerified">
                            ({{ getPhysicalStatus(assetData.verification.physical.state) }})
                        </template>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-4">
                    <div class="fw-bold">Building</div>
                    <div v-if="assetData.buildingInfo">
                        {{ assetData.buildingInfo.name }} ({{ this.assetData.original?.buildingId }})
                    </div>
                    <div v-else>{{ NA }}</div>
                    <address v-if="assetData.buildingInfo?.address">
                        <div>{{ `${assetData.buildingInfo.address.line_1} ${assetData.buildingInfo.address.line_2}` }}</div>
                        <div>{{ `${assetData.buildingInfo.address.city}, ${assetData.buildingInfo.address.state}` }}</div>
                        <div>{{ assetData.buildingInfo.address.zip }}</div>
                    </address>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold">Location</div>
                    <div v-if="assetData.locationName">
                        {{ assetData.locationName }} ({{ assetData.original?.locationId }})
                    </div>
                    <div v-else>{{ NA }}</div>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold">ServiceNow Last Update</div>
                    <div>
                        {{
                            assetData.original?.lastUpdated?.datetime
                                ? this.getDate(this.assetData.original?.lastUpdated?.datetime?.seconds)
                                : NA
                        }}
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="fw-bold">Digital Verification</div>
                    <div>
                        {{
                            this.assetData.verification?.digital ?
                            `${this.getDigitalStatus(this.assetData.verification.digital.state)} |
                            ${this.getDate(this.assetData.verification.digital.when)}`
                            : NA
                        }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold">Physical Verification</div>
                    <div v-if="assetData.verification?.physical">
                        {{ this.getPhysicalStatus(this.assetData.verification.physical.state) }} |
                        <span
                            v-if="
                                assetData.verification.physical.verifiedWithResolvedConflicts ||
                                assetData.verification.physical.verifiedWithoutConflicts
                            "
                        >
                            {{
                                `${getDate(
                                    assetData.verification.physical.verifiedWithResolvedConflicts?.when?.seconds ||
                                        assetData.verification.physical.verifiedWithoutConflicts?.when?.seconds
                                )} by ${getUserName(
                                    assetData.verification.physical.verifiedWithResolvedConflicts?.by ||
                                        assetData.verification.physical.verifiedWithoutConflicts?.by
                                )}`
                            }}
                        </span>
                        <span v-else>{{ NA }}</span>
                    </div>
                    <div v-else>{{ NA }}</div>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold">In-tune Registration</div>
                    <div>
                        {{ this.assetData.original?.isRegisteredOnInTune ? 'Yes' : 'No' }}
                    </div>
                </div>
            </div>
            <div class="mt-4 pt-3 fw-bold">Attributes</div>
            <div class="row">
                <div class="col-md-2 fw-bold">Type Id:</div>
                <div class="col-md-8">
                    <span>{{ this.assetData.original?.typeId }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 fw-bold">Manufacturer:</div>
                <div class="col-md-8">
                    <span>{{ this.assetData.original?.manufacturer }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 fw-bold">Model:</div>
                <div class="col-md-8">
                    <span>{{ this.assetData.original?.model }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 fw-bold">Operational:</div>
                <div class="col-md-8">
                    <span>{{ this.assetData.original?.operational }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 fw-bold">Physical Condition:</div>
                <div class="col-md-8">
                    <span>{{ this.assetData.original?.physicalCondition }}</span>
                </div>
            </div>
        </div>
        <template #footer>
            <hr />
            <Button label="OK" @click="$emit('toggleModal')" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Utility from '../../../../shared/utility/utility';
import { AssetState, DigitalStatusAssets, PhysicalStatusAssets } from '../../../../shared/enums';
import { NA } from '../../../../shared/constants/constants';

export default {
    name: 'ViewAsset',
    components: {
        Dialog,
        Button
    },
    props: {
        assetData: Object
    },
    data() {
        return {
            AssetState,
            showDialog: true,
            NA: NA
        };
    },
    methods: {
        getDate(val) {
            return Utility.formateDate(val);
        },
        getStatus(val) {
            return Utility.getKeyByValue(AssetState, val);
        },
        getDigitalStatus(val) {
            return Utility.getKeyByValue(DigitalStatusAssets, val);
        },
        getPhysicalStatus(val) {
            return Utility.getKeyByValue(PhysicalStatusAssets, val);
        },
        getUserName(id) {
            return this.assetData.usersObj[id];
        }
    }
};
</script>
