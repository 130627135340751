<template>
    <div class="aset-scrolling-area m-1">
    <nav class="text-black-50" aria-label="breadcrumb" v-if="selectedVisit?.visitLocationInfo">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">{{ selectedVisit?.visitLocationInfo.districtName }}</li>
            <li class="breadcrumb-item">{{ selectedVisit?.visitLocationInfo.buildingId }}</li>
        </ol>
    </nav>
    <DataTable
        class="mt-3"
        :value="assets"
        v-model:selection="selectedAsset"
        selectionMode="single"
        dataKey="id"
        :loading="loading"
        ref="dt"
        v-model:first="lazyParams.first"
        :rows="rows"
        :sortField="assetSortObj?.sortField || 'original.assetTypeName'"
        :sortOrder="assetSortObj?.sortOrder || sortOrder.Ascending"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        @rowSelect="onRowSelect"
        responsiveLayout="scroll"
    >
        <template #header>
        <div class="row">
            <div class="col-md-1" >
                <Button class="p-button-text" @click="displayFilter = true">
                    <img src="../../../../assets/filter-icon.png" />
                    <span class="ms-2 color-lavender-purple">Filter</span>
                </Button>
            </div>
            <div class="col-md-6" v-if=" (NAVIGATIONCONST.RESOLVECONFLICTS == $route.name)">
                    <Dropdown
                        filter
                        v-model="selectedVisit"
                        :options="visits"
                        @change="onVisitChange"
                        optionLabel="title"
                        placeholder="Select Visit"
                        class="w-100"
                        panelClass="dropdown-panel"
                    />
            </div>
            <div class="col-md-3" v-if="(NAVIGATIONCONST.RESOLVECONFLICTS == $route.name) && selectedVisit">
                 <Button class="certify-btn mr-20" label="SUMMARY" :disabled="showCertifyButton" @click="showCertificationModal = true"/>
                 <Button class="certify-btn" label="FINALIZE" @click="showFinalizeModal = true"/>
            </div>
            <template v-if="NAVIGATIONCONST.RESOLVECONFLICTS == $route.name && selectedVisit">
                <div class="col-md-2">
                <ToggleButton v-model="showNewAssets" @change="onShowNewAssetsChange" onIcon="pi pi-check" offIcon="pi pi-times"
                    class="w-full sm:w-10rem float-end" aria-label="Confirmation" onLabel="New" offLabel="New" />
                </div>
            </template>
        </div>
        </template>
        <template #empty>No records found</template>
        <Column field="original.assetTag" header="Asset Tag" class="asset-tag-width" sortable>
            <template #body="{ data }">
                <span>
                    <!--<span class="legend-key ms-0" :class="getStateClass(data.state,data)"></span>-->
                    {{ data.original?.assetTag }}
                </span>
            </template>
        </Column>
        <Column field="original.serialNumber" header="Serial Number" class="serial-no-width" sortable>
            <template #body="{ data }">
                {{ data.original?.serialNumber }}
            </template>
        </Column>
        <Column field="original.assetTypeName" header="Type" sortable>
            <template #body="{ data }">
                {{ data.original.assetTypeName }}
            </template>
        </Column>
        <Column field="original.buildingId" header="Building" class="building-column-width" sortable>
            <template #body="{ data: { original } }">
                {{ original?.buildingId ? original.buildingId + ':' + this.buildingObj[original.buildingId]?.name : NA }}
            </template>
        </Column>
        <Column field="original.locationId" header="Location" class="location-column-width" sortable>
            <template #body="{ data: { original } }">
                <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="original?.locationId ? original.locationId + ':' + this.locationObj[original.locationId] : NA"
                >
                    {{ original?.locationId ? original.locationId + ':' + this.locationObj[original.locationId] : NA }}
                </span>
            </template>
        </Column>
        <Column field="original.lastUpdatedFromServiceNow" header="Last Updated">
            <template #body="{ data: { original } }">
                {{ Utility.formateDate(original?.lastUpdated?.datetime?._seconds || original?.lastUpdated?.datetime) || NA }}
            </template>
        </Column>
        <Column field="verification" header="Last Verified" hidden>
            <template #body="{ data: { verification } }">
                {{
                    verification?.physical?.when || verification?.digital?.when
                        ? Utility.formateDate(
                              verification.physical?.when > verification.digital?.when
                                  ? verification.physical?.when
                                  : verification.digital?.when
                          )
                        : NA
                }}
            </template>
        </Column>
        <template #paginatorstart> </template>
    </DataTable>
    <AssetsFilter
        v-if="displayFilter"
        :selected="filterSelected"
        :assetTypes="assetTypes"
        :showLocation="NAVIGATIONCONST.ASSETS == $route.name || NAVIGATIONCONST.RESOLVECONFLICTS == $route.name"
        @applyFilter="applyFilter"
        @closeFilter="displayFilter = false"
    />
    <CertifyVisit v-if="showCertificationModal" @showHideCertificationModal="filterSelectedVisit"
     :visitId="selectedVisit.id" :buildingCode="selectedVisit.visitLocationInfo.buildingId"/>
    <FinalizeVisit v-if="showFinalizeModal" @toggleFinalizeModal="visitFinalized" :visit="selectedVisit"/>
    <div v-if="showModal">
        <ViewAsset @toggleModal="showModal = false" :assetData="assetData" />
    </div>
    </div>
</template>

<script>
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import { ref } from 'vue';
import { FilterOperator, FilterMatchMode } from 'primevue/api';
import AssetsFilter from './AssetsFilter.vue';
import ViewAsset from './ViewAsset.vue';
import CertifyVisit from './CertifyVisit.vue';
import FinalizeVisit from './FinalizeVisit.vue';
import store from '../../../../shared/store';
import { isNavigationAllowed, Utility } from '../../../../shared/utils';
import { AssetState } from '../../../../shared/enums';
import {
    rowPerPageOptions,
    rows,
    NA,
    msgConflictResolution,
    toasterTime,
    NAVIGATIONCONST,
    sortOrder,
    windowBufferHeight,
    localStorageKeys,
    messages,
    toasterTimeForLongMsg
} from '../../../../shared/constants';
import { assetsFilterUrl } from '../../../../shared/constants/constants';
import { sendRequest } from '../../../../shared/utils';
import {
    AssetService,
    LocationService,
    BuildingService,
    AssetTypeService,
    UserService,
    VisitService,
    VisitActivityService
} from '../../../../apis';

export default {
    name: 'AssetTable',
    components: {
        DataTable,
        Column,
        ViewAsset,
        AssetsFilter,
        Button,
        CertifyVisit,
        Dropdown,
        ToggleButton,
        FinalizeVisit
    },
    data() {
        return {
            visitId: null,
            NAVIGATIONCONST,
            buildingSort: true,
            rowPerPageOptions: rowPerPageOptions,
            rows: rows,
            NA: NA,
            assets: [],
            loading: false,
            selectedAsset: null,
            totalRecords: ref(0),
            lazyParams: {},
            displayFilter: false,
            selectedVisit: null,
            visits: [],
            filters: {
                'state': { value: null, matchMode: FilterMatchMode.EQUALS },
                'original.typeId': { value: [], matchMode: FilterMatchMode.IN },
                'original.buildingId': { value: [], matchMode: FilterMatchMode.IN }
            },
            filterSelected: {
                state: null,
                assetTypes: [],
                locationKeys: {}
            },
            showModal: false,
            assetData: null,
            locationObj: {},
            buildingObj: {},
            assetTypes: [],
            conflicDataIdentifier: null,
            sortOrder,
            typeIdSort: true,
            usersObj: {},
            hasMoreData: false,
            showCertificationModal: false,
            showFinalizeModal: false,
            showCertifyButton: true,
            selectedBuildings: [],
            showNewAssets: false,
            buildingCode: null,
            assetStatesToBeIgnoredForCertify: AssetState.InConflict,
            localStorageKeys,
            isResolveConflictScreen: this.$route.name === NAVIGATIONCONST.RESOLVECONFLICTS,
            loggedInUserId: this.$store.getters.getSessionUser.uid,
            Utility
        };
    },
    computed: {
        isResolveConflictRoute() {
            return NAVIGATIONCONST.RESOLVECONFLICTS == this.$route.name || NAVIGATIONCONST.RESOLVESCANCONFLICTS == this.$route.name;
        }
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    async mounted() {
        const filtersCond = {
            [NAVIGATIONCONST.ASSETS]: this.filters,
            [NAVIGATIONCONST.OURASSETS]: {
                ...this.filters,
                'original.buildingId': {
                    value: await this.getBuildingIds(),
                    matchMode: FilterMatchMode.IN,
                    operator: FilterOperator.OR,
                    constraints: [{ 'resolve.buildingId': { value: await this.getBuildingIds(), matchMode: FilterMatchMode.IN } }]
                }
            },
            [NAVIGATIONCONST.RESOLVECONFLICTS]: {
                ...this.filters,
                state: { value: AssetState.InConflict, matchMode: FilterMatchMode.EQUALS }
            }
        };
        filtersCond[NAVIGATIONCONST.RESOLVESCANCONFLICTS] = {
            ...filtersCond[NAVIGATIONCONST.OURASSETS],
            state: { value: AssetState.InConflict, matchMode: FilterMatchMode.EQUALS}
        };
        const filtersState = {
            [NAVIGATIONCONST.ASSETS]: this.filterSelected,
            [NAVIGATIONCONST.OURASSETS]: this.filterSelected,
            [NAVIGATIONCONST.RESOLVECONFLICTS]: {
                ...this.filterSelected,
                state: AssetState.InConflict
            },
            [NAVIGATIONCONST.RESOLVESCANCONFLICTS]: {
                ...this.filterSelected,
               state: AssetState.InConflict
            }
        };
        const path = this.$route.name;
        this.filters = filtersCond[path];
        this.filterSelected = filtersState[path];
        this.lazyParams = {
            page: 0,
            first: 0,
            rows: rows,
            sortField: 'original.typeId',
            sortOrder: sortOrder.Ascending,
            filters: this.filters
        };
        this.getAssetsTypes();
        let userFilterOption;
        if (this.isResolveConflictRoute) {
            this.selectedVisit = JSON.parse(localStorage.getItem(`${ localStorageKeys.selectedVisit }-${ this.loggedInUserId }`));
            const resolveConflictFilter = JSON.parse(localStorage.getItem(
                `${ localStorageKeys.resolveConflictFilter }-${ this.loggedInUserId }`));
            userFilterOption = resolveConflictFilter?.path == path ? resolveConflictFilter : {};
            this.assetSortObj = store.getters.getAssetSortObj;
        }
        if (userFilterOption?.path) {
            await this.applyFilter(
                userFilterOption.state,
                userFilterOption.assetTypes,
                userFilterOption.locationKeys,
                userFilterOption.buildings,
                true
            );
        } else {
            this.visits = await VisitService.getVisitByAssetFilter();
            await this.showdata();
            if (this.$route.query.s == AssetState.ManualVerified) {
                this.$toast.add({ ...msgConflictResolution.successResolve, closable: false, life: toasterTime });
                this.$router.replace({ name: this.$route.name });
            }
        }
        if (this.filters.state?.value) {
            this.conflicDataIdentifier = true;
        }
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        visitFinalized(isVisitFinalized) {
            this.showFinalizeModal = false;
            if (isVisitFinalized) {
                this.assets = [];
                this.visits = this.visits.filter(x => x.id != this.selectedVisit.id);
                localStorage.removeItem(`${ localStorageKeys.selectedVisit }-${ this.loggedInUserId }`);
                this.selectedVisit = null;
            }
        },
        filterSelectedVisit(isSelectedVisitCertified) {
            if (isSelectedVisitCertified) {
                if (this.filters.state.value != AssetState.Deferred) {
                    this.visits = this.visits.filter(visit => visit.id != this.selectedVisit.id);
                }
                this.selectedVisit = this.visits[0];
                this.onVisitChange();
            }
            this.showCertificationModal = false;
        },
        async handleScroll() {
            const bottomOfWindow = Math.ceil(document.documentElement.scrollTop + window.innerHeight + windowBufferHeight) >=
                    Math.ceil(document.documentElement.offsetHeight);
            if (bottomOfWindow && this.hasMoreData) {
                this.lazyParams.page++;
                this.lazyParams.first = (this.lazyParams.page) * this.lazyParams.rows;
                this.showdata();
            }
        },
        async getBuildingIds() {
            if (!isNavigationAllowed(this.$route.name)) {
                return [];
            }
            const userDetails = this.$store.getters.userDetails;
            const buildingIds = (userDetails.roleData && userDetails.roleData[userDetails.role]?.buildings) || ['NA'];
            return buildingIds;
        },
        onShowNewAssetsChange() {
            this.assets = [];
            localStorage.setItem(`${ localStorageKeys.showNewAssets }-${ this.loggedInUserId }`,
                this.showNewAssets);
            this.showdata();
        },
        async showdata() {
            this.loading = true;
            this.lazyParams.filters = this.filters;
            let assetIds = [];
            try {
                this.showNewAssets = JSON.parse(localStorage.getItem(`${ localStorageKeys.showNewAssets }-${ this.loggedInUserId }`)) ||
                    this.showNewAssets;
                let requestData = {};
                if (this.selectedVisit) {
                    requestData = {
                        visitId: this.selectedVisit.id,
                        state: this.filters['state'].value,
                        typeId: this.filters['original.typeId'].value
                    };
                    assetIds = await VisitActivityService.getAssetList(this.selectedVisit.id);
                }
                let assets = (this.isResolveConflictRoute) ? (this.showNewAssets && this.selectedVisit ?
                    await sendRequest(requestData, assetsFilterUrl) :
                    await AssetService.fetchOldAssetByIds(assetIds)) :
                    await AssetService.getAll(this.lazyParams);
                assets = assets.filter(x => x.scanned?.length);
                for (let i = 0; i < assets.length; i++) {
                    const asset = {
                        ...assets[i],
                        view: assets[i]
                    };
                    if (asset.scanned?.length) {
                        const allKeys = new Set(
                            asset.scanned
                                .map(({ data }) => Object.keys(data))
                                .flat()
                                .concat(asset.original)
                        );
                        asset.scanned.forEach(({ data }) =>
                            allKeys.forEach(key => {
                                if (!asset.original[key] && data[key]) {
                                    asset.original[key] = data[key].value || data[key];
                                }
                            })
                        );
                    }
                    assets[i] = asset;
                    assets[i].original['assetTypeName'] = this.assetTypesMap[assets[i].original.typeId];
                }

                const locationIds = [];
                const buildingId = [];
                const userIds = [];
                assets.forEach(({ original, verification }) => {
                    if (original?.locationId) {
                        locationIds.push(original.locationId);
                    }
                    if (original?.buildingId) {
                        buildingId.push(original.buildingId);
                    }
                    if (verification?.physical?.verifiedWithResolvedConflicts?.by || verification?.physical?.verifiedWithoutConflicts?.by) {
                        userIds.push(
                            verification.physical?.verifiedWithResolvedConflicts?.by || verification.physical?.verifiedWithoutConflicts?.by
                        );
                    }
                });
                const [locations, buildings, users] = await Promise.all([
                    LocationService.getByIds(locationIds),
                    BuildingService.getByIds(buildingId),
                    UserService.getByIds(userIds)
                ]);
                locations.forEach(ele => (this.locationObj[ele.id] = ele.name));
                buildings.forEach(ele => (this.buildingObj[ele.id] = { name: ele.name, address: ele.address }));
                users.forEach(ele => (this.usersObj[ele.id] = ele.name));
                this.hasMoreData = assets.length > 0;
                if (this.selectedVisit) {
                    this.assets = [];
                    this.assets.push(...assets);
                    this.hasMoreData = false;
                } else {
                    this.assets.push(...assets);
                }

                if (this.isResolveConflictRoute && this.selectedVisit && this.filters.state) {
                    this.showCertifyButton = this.checkAssetState(this.assets);
                    this.assets = this.assets.filter(x=>x.state == this.filters.state.value);
                    this.filterAssetsByAssetType();
                    this.$store.commit('setConflictedAssets', this.assets);
                }
            } catch (err) {
                console.error(err);
                this.$toast.add({
                    severity: 'error',
                    closable: false,
                    detail: err,
                    life: toasterTime
                });
            } finally {
                this.loading = false;
            }
        },
        checkAssetState(asset) {
            return (asset.length == 0) || (asset.filter(x=> this.assetStatesToBeIgnoredForCertify == x.state)).length;
        },
        onSort(event) {
            if (this.isResolveConflictRoute) {
                this.assetSortObj = {
                    sortOrder: event?.sortOrder,
                    sortField: event?.sortField
                };
                store.commit('setAssetSortObj', this.assetSortObj);
            }
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.showdata();
        },
        filterAssetsByAssetType() {
            const assetTypes = this.filters['original.typeId'].value;
            this.assets = assetTypes.length>0 ? this.assets.filter(x=> assetTypes.includes(x.original.typeId)) : this.assets;
        },
        getStateClass(rawState, data) {
            if (this.isResolveConflictRoute) {
                switch (rawState) {
                case 0:
                case 1:
                    return 'dark-pink-background';
                case 2:
                    if (data.resolved && Object.keys(data.resolved).length > 0) {
                        return 'amber-background';
                    }
                default:
                    return 'light-green-background';
                }
            } else {
                switch (rawState) {
                case 0:
                    return 'gray-background';
                case 1:
                    return 'dark-pink-background';
                default:
                    return 'light-green-background';
                }
            }
        },
        onRowSelect(event) {
            if (this.isResolveConflictRoute && isNavigationAllowed(this.$route.name)) {
                this.$router.push({ name: NAVIGATIONCONST.RESOLVECONFLICT, params: { assetId: event.data.id } });
                return;
            }
            const data = event.data;
            data.locationName = this.locationObj[data.original?.locationId];
            data.buildingInfo = this.buildingObj[data.original?.buildingId];
            data.assetType = this.assetTypesMap[data.original?.typeId?.value || data.original?.typeId];
            data.usersObj = this.usersObj;
            this.assetData = data;
            this.showModal = true;
        },
        async getAssetsTypes() {
            const res = await AssetTypeService.getAll();
            this.assetTypes = res.sort((a, b) => a.name.localeCompare(b.name));
            this.assetTypesMap = Object.fromEntries(res.map(({ id, name }) => [id, name]));
        },
        async applyFilter(state, assetTypes, locationKeys, { buildings }, isFilterApplied) {
            if (!this.isResolveConflictRoute) {
                if (assetTypes?.length > 1 && buildings?.length > 1) {
                    this.$toast.add({
                        severity: 'error',
                        closable: false,
                        detail: messages.assetValidation.filterError,
                        life: toasterTimeForLongMsg
                    });
                    return;
                } else {
                    this.updateFilterMatchMode(assetTypes, buildings);
                }
            }
            if (!isFilterApplied && this.isResolveConflictScreen) {
                localStorage.removeItem(`${ localStorageKeys.selectedVisit }-${ this.loggedInUserId }`);
            }
            this.hasMoreData = false;
            this.filterSelected = {
                ...this.filterSelected,
                state,
                assetTypes,
                locationKeys
            };
            const resolveConflictFilter = {
                state: state,
                assetTypes: assetTypes,
                locationKeys: locationKeys,
                buildings: { buildings },
                path: this.$route.name
            };
            this.isResolveConflictScreen &&
            localStorage.setItem(`${ localStorageKeys.resolveConflictFilter }-${ this.loggedInUserId }`,
                JSON.stringify(resolveConflictFilter));
            this.typeIdSort = !assetTypes.length;
            this.buildingSort = !(buildings && buildings.length);
            this.filters.state.value = state;
            this.filters['original.typeId'].value =
                assetTypes && this.filters['original.typeId'].matchMode == FilterMatchMode.EQUALS ? assetTypes[0] : assetTypes;
            this.filters['original.buildingId'].value =
                buildings && this.filters['original.buildingId'].matchMode == FilterMatchMode.EQUALS ? buildings[0] : buildings;
            this.lazyParams.filters = this.filters;
            this.lazyParams.first = 0;
            this.lazyParams.page = 0;
            this.assets = [];
            this.visits = await VisitService.getVisitByAssetFilter(this.filters);
            if (!isFilterApplied || (isFilterApplied && this.selectedVisit && this.visits.map(object => object.id).indexOf(this.selectedVisit.id)<0)) {
                this.selectedVisit = null;
            }
            await this.showdata();
            this.displayFilter = false;
        },
        updateFilterMatchMode(assetTypes, buildings) {
            const matctModeEquals = { value: null, matchMode: FilterMatchMode.EQUALS };
            const matchModeIn = { value: [], matchMode: FilterMatchMode.IN };
            const assetTypeCount = assetTypes?.length;
            const buildingCount = buildings?.length;
            if (assetTypeCount == 1 && buildingCount == 1) {
                this.filters['original.typeId'] = matctModeEquals;
                this.filters['original.buildingId'] = matctModeEquals;
            } else if (assetTypeCount == 1 && buildingCount > 1) {
                this.filters['original.typeId'] = matctModeEquals;
                this.filters['original.buildingId'] = matchModeIn;
            } else if (assetTypeCount > 1 && buildingCount == 1) {
                this.filters['original.typeId'] = matchModeIn;
                this.filters['original.buildingId'] = matctModeEquals;
            }
        },
        async onVisitChange() {
            this.showNewAssets = false;
            localStorage.setItem(`${ localStorageKeys.showNewAssets }-${ this.loggedInUserId }`,
                this.showNewAssets);
            localStorage.setItem(`${ localStorageKeys.selectedVisit }-${ this.loggedInUserId }`, JSON.stringify(this.selectedVisit));
            this.$store.commit('setConflictedAssets', []);
            await this.showdata();
        }
    }
};
</script>
<style>
.certify-btn{
    height: 3.188rem !important;
    margin-right: 20px;
}
.p-dropdown-panel.dropdown-panel {
    width: 6vw !important;
}
.head-color{
    background-color: rgb(177, 187, 227);
    color: white;
}
.row-color tbody tr:nth-child(even) {
    background-color: rgb(239, 237, 237);
}
.row-color tbody tr:nth-child(odd) {
    background-color:  #DFE7F2;
}
.serial-no-width {
    max-width: 18rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.asset-tag-width,
.building-column-width {
    width: 15rem;
}
.location-column-width {
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
