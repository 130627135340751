<template>
    <Dialog
        header="Filter"
        @hide="$emit('closeFilter')"
        position="topright"
        v-model:visible="visible"
        :style="{ width: '25.25rem' }"
        :draggable="false"
    >
        <div class="mb-4">
            <div class="size14 color-dark-pink font-weight-500 m-1 mb-4 me-3 float-end" role="button" @click="clearFilters">CLEAR</div>
            <div class="size14 color-lavender-purple font-weight-500 m-1 mb-4 me-3 float-end" role="button"
             @click="applyfilter()">APPLY</div>
        </div>
            <div class="text-muted fs-5 font-weight-500">Status</div>
            <div class="field-checkbox margin-top-12 mb-4">
                <Dropdown
                    class="w-100"
                    v-model="selectedState"
                    :options="status"
                    optionLabel="0"
                    optionValue="1"
                    placeholder="Select a State"
                />
            </div>
        <Button label="Asset Types" @click="showTypeModal = true" class="me-3 justify-content-center button-color-lavender-purple w-100" />
        <div class="mt-2 d-inline-flex" v-for="assetType in selectedTypes" :key="assetType">
            <Chip :label="assetTypes.find(x => x.id == assetType).name" removable @remove="removeAssetType(assetType)" class="m-1"></Chip>
        </div>
        <template v-if="showLocation">
            <div class="text-muted fs-5 font-weight-500 mt-4">Locations</div>
            <div class="field-checkbox margin-top-12">
                <LocationSelection :showTree="true" :selectedLocations="selectedLocations" @selectTree="applyTree" />
            </div>
        </template>
        <p v-if="errMessage" class="error-message mt-2">{{ errMessage }}</p>
    </Dialog>
    <Dialog
                header='Asset Types'
                :modal="true"
                :closable="false"
                :style="{height: '30vw', width: '50vw'}"
                v-model:visible="showTypeModal"
                >
                <div class="field-checkbox mt-2" v-for="assetType in assetTypes" :key="assetType">
                    <Checkbox :value="assetType.id" v-model="selectedTypes" @click="errMessage = ''" />
                    <label for="free" class="size14 fw-normal ms-3">{{ assetType.name }}</label>
                </div>
                <template #footer>
                    <Button label="CANCEL" @click="toggleAssetTypeModal(true)" class="p-button-outlined float-end me-3" />
                    <Button label="OK" @click="toggleAssetTypeModal(false)" class="float-end me-3"/>
                </template>
    </Dialog>
</template>

<script>
import Chip from 'primevue/chip';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { AssetState } from '../../../../shared/enums';
import { checkboxOptionLimitMessage } from '../../../../shared/constants';
import LocationSelection from '../../../../shared/LocationSelection.vue';
import { NAVIGATIONCONST } from '../../../../shared/constants/navigation-constant';

export default {
    name: 'AssetsFilter',
    components: {
        Chip,
        Button,
        Dialog,
        Dropdown,
        Checkbox,
        LocationSelection
    },
    props: {
        assetTypes: Array,
        selected: {
            type: Object,
            default: () => ({})
        },
        showLocation: Boolean
    },
    data() {
        return {
            showTypeModal: false,
            selectedState: this.selected.state,
            selectedTypes: this.selected.assetTypes,
            selectedLocations: this.selected.locationKeys,
            filterObj: {},
            status: Object.entries(AssetState).filter(x => x[1] != AssetState.SentToServiceNow).sort((a, b) => a[0].localeCompare(b[0])),
            errMessage: '',
            checkboxOptionLimitMessage,
            visible: true,
            tempSelectedAssetType: this.selected.assetTypes || []
        };
    },
    methods: {
        removeAssetType(type) {
            this.selectedTypes = this.selectedTypes.filter(x => x != type);
            this.tempSelectedAssetType = this.selectedTypes;
        },
        toggleAssetTypeModal(isCancelBtnClicked) {
            if (isCancelBtnClicked) {
                this.selectedTypes = this.tempSelectedAssetType;
            } else {
                this.tempSelectedAssetType = this.selectedTypes;
            }
            this.showTypeModal = false;
        },
        clearFilters() {
            this.selectedState = this.$route.name === NAVIGATIONCONST.RESOLVECONFLICTS ? AssetState.InConflict : null;
            this.selectedTypes = [];
            this.selectedLocations = {};
            this.filterObj = {};
            this.applyfilter(false);
        },
        applyfilter(isFilterApplied = true) {
            this.$emit('applyFilter', this.selectedState, this.selectedTypes, this.selectedLocations, this.filterObj, isFilterApplied);
        },
        applyTree(selections, filterObj) {
            this.selectedLocations = selections;
            this.filterObj = filterObj;
        }
    }
};
</script>
