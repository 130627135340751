<template>
    <Dialog v-model:visible="showModal"
        :modal="true" :closable="false" :style="{width: '60vw'}">
    <template #header>
		<h3>{{buildingCode}}</h3>
	</template>
        <div class="container-fluid mb-2">
            <table class="w-100">
                <thead class="text-center" >
                    <th class="rowColor">Conflict Resolution Summary</th>
                </thead>
                <tbody>
                    <tr class="row rowText">
                        <td class="col-md-8">
                            Total Count
                        </td>
                        <th class="col-md-4">
                            {{Utility.getSum('total', this.data)}}
                        </th>
                    </tr>
                    <tr class="row rowText">
                        <td class="col-md-8">
                            Auto Resolved
                        </td>
                        <th class="col-md-4">
                            {{Utility.getSum('autoResolved', this.data)}}
                        </th>
                    </tr>
                    <tr class="row rowText">
                        <td class="col-md-8">
                            Manually Resolved
                        </td>
                        <th class="col-md-4">
                            {{Utility.getSum('manuallyResolved', this.data)}}
                        </th>
                    </tr>
                    <tr class="row rowText">
                        <td class="col-md-8">
                            In Conflict
                        </td>
                        <th class="col-md-4">
                            {{Utility.getSum('inConflict', this.data)}}
                        </th>
                    </tr>
                    <tr class="row rowText">
                        <td class="col-md-8">
                            Deferred
                        </td>
                        <th class="col-md-4">
                            {{Utility.getSum('deferred', this.data)}}
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
        <AssetCountTable :data="data" :loading="loading" />
    <template #footer>
        <Button class="me-1" label="CERTIFY" @click="certifyVisit" :disabled="displayLoader"/>
        <Button label="CANCEL" @click="$emit('showHideCertificationModal', false)" class="me-3 p-button-outlined" :disabled="displayLoader"/>
    </template>
    <ProgressSpinner
            class="p-component-overlay position-fixed w-100 h-100"
            strokeWidth="4"
            v-if="displayLoader"
            />
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import AssetCountTable from './AssetCountTable.vue';
import { VisitActivityService, AssetService, VisitService } from '../../../../apis';
import { Utility } from '../../../../shared/utils';
import { UserActivity } from '../../../../shared/utils';
import { VisitStatus } from '../../../../shared/enums/visit-status';
import { toasterTime, messages, userActivities, toasterType } from '../../../../shared/constants/constants';
import ProgressSpinner from 'primevue/progressspinner';


export default {
    name: 'CertifyVisit',
    components: {
        Dialog,
        Button,
        AssetCountTable,
        ProgressSpinner
    },
    props: {
        visitId: String,
        buildingCode: String
    },
    data() {
        return {
            showModal: true,
            data: [],
            loading: true,
            visit: {},
            userActivities,
            Utility,
            displayLoader: true
        };
    },
    async mounted() {
        const assetIdArray = await VisitActivityService.getAssetList(this.visitId);
        this.data = await AssetService.getAssetCount(assetIdArray);
        this.displayLoader = false;
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async certifyVisit() {
            try {
                this.displayLoader = true;
                const visitData = {
                    state: VisitStatus.ApproveForSNOW,
                    info: {
                        ...UserActivity.getActivity(userActivities.updated),
                        ...UserActivity.getActivity(userActivities.approveForSNOW, true)
                    }
                };
                await VisitService.update(this.visitId, visitData);
                this.showToaster(messages.visitValidation.visitApprovedSuccess, toasterType.success);
                this.$emit('showHideCertificationModal', true);
            } catch (err) {
                this.showToaster(messages.visitValidation.visitApprovedFailed, toasterType.error);
            } finally {
                this.displayLoader = false;
            }
        }
    }
};
</script>
<style>
.rowColor {
    background-color: rgba(0, 0, 0, 0.04) !important;
    color: black !important;
    width: 0 0 1px 0 !important;
    padding: 0.75rem 0.75rem !important;
    }
.rowText {
    border: 1px solid #e4e4e4 !important;
    border-width: 0 0 1px 0 !important;
    padding: 0.75rem 0.75rem !important;
}
.p-progress-spinner-svg {
    width: 6.25rem !important;
}
</style>

