<template>
  <div class="container container-width">
     <AssetTable/>
  </div>
</template>
<script>
import AssetTable from '../asset-details/AssetTable.vue';

export default {
    name: 'assets',
    components: {
        AssetTable
    }
};
</script>
