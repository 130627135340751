<template>
    <div class="container mt-3" style="display:none">
        <DataTable :loading="loading" :value="data" rowGroupMode="rowspan" groupRowsBy="provider" showGridlines responsiveLayout="scroll">
            <Column field="provider" header="">
                <template #body="slotProps">
                    <span>{{getProviderString(slotProps.data.provider)}}</span>
                </template>
            </Column>
            <Column header="" headerStyle="width:3em">
                <template #body="slotProps">
                    {{slotProps.data.type}}
                </template>
            </Column>
            <Column class="text-end" v-for="item in columns" :key="item.key" :field="item.key" :header="item.header">
                <template #footer>
                    {{getSum(item.key, this.data)}}
                </template>
            </Column>
        </DataTable>
    </div>

</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { Utility } from '../../../../shared/utils';
export default {
    components: {
        DataTable,
        Column
    },
    props: {
        data: Array,
        loading: Boolean
    },
    data() {
        return {
            columns: [
                { key: 'total', header: 'Total' },
                { key: 'inConflict', header: 'In Conflict' },
                { key: 'autoResolved', header: 'Auto Resolved' },
                { key: 'manuallyResolved', header: 'Manually Resolved' },
                { key: 'readyForSNOW', header: 'Ready For SNOW' }
            ]
        };
    },
    methods: {
        getSum(target, data) {
            return Utility.getSum(target, data);
        },
        getProviderString(provider) {
            return Utility.getProviderString(provider);
        }
    }
};
</script>
<style>
.text-end > .p-column-header-content{
    float: right !important;
}
</style>
